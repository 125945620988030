let val=24512
export default  {
  title: {
    text: `${val}盘\n\n总育秧量`,
    top:"40%",
    left: 'center',
    textStyle:{
       fontSize:20,
       color:'#fff',
       width:15
    }
  },
  color:['rgb(183,184,182)','rgb(105,175,115)',] ,
  tooltip: {
    trigger: 'item'
  },
  legend: {
    bottom:'0%',
    left: 'center',
    icon:'circle',
    textStyle:{
      fontSize:18,
      color:'#fff',
    }
  },
  series: [
    {
      name: '总育秧量',
      type: 'pie',
      radius: ['60%','70%'],
      label:{
        show:false
      },
      data: [
        { value: 25, name:'对外育苗', },
        { value: 75, name: '自我育苗', },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}


