<template>
  <div class="dry-power">
    <div class="power">
      <div class="content-item">
        <span class="item-name">总育秧批次</span>
        <div class="item-number">{{ countBatch }}批</div>
      </div>
      <div class="content-item">
        <span class="item-name">总育秧量（盘）</span>
        <div class="item-number">
          {{ countSeeding }}
        </div>
      </div>
    </div>
    <!-- 线条 -->
    <div class="line"></div>
    <!-- 滚动 -->
    <div class="dry-power-content">
      <div class="power-content-top">
        <div class="content-top-item">
          <span class="top-item-name">育秧批次</span>
          <div class="top-item-number">
            <el-select v-model="value1" placeholder="请选择" @change="getname">
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.patch"
                :value="item.patch"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content-top-item" @click="getvarirty">
          <span class="top-item-name">育秧品种</span>
          <div class="top-item-number" :title="seedingVariety">{{ seedingVariety }}</div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">种子来源</span>
          <div class="top-item-number" :title="seedSource">{{ seedSource }}</div>
        </div>
      </div>
      <div class="power-content-bottom power-content-top">
        <div class="content-top-item">
          <span class="top-item-name">育秧时间</span>
          <div class="top-item-number">{{ date }}</div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">自用盘数</span>
          <div class="top-item-number">{{ plateMyself }}</div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">对外盘数（盘）</span>
          <div class="top-item-number">{{ plateOut }}</div>
        </div>
      </div>
    </div>
    <variety :flag="flag" @closemask="closemask" :count="count"></variety>
  </div>
</template>

<script>
import moduleTitle from '../../moduleTitle.vue';
import variety from './variety.vue';
import {
  getSeedingYear,
  getSelect,
  getSeedingYearName,
  getSeedingVarietyName
} from '@/api/seedlingApi/seedling.js';

export default {
  components: {
    moduleTitle,
    variety
  },
  created () {
    this.getList();
    this.getselect();
  },
  data () {
    return {
      flag: false,
      // 总育盘数
      countSeeding: '',
      // 总批次
      countBatch: '',
      // 日期时间
      date: '',
      // 批次选择框
      options: [],
      // 选择框默认数据
      value1: '',
      // 批次时间
      seedingBeginTime: '',
      // 批次自用盘数
      plateMyself: 0,
      // 批次对外盘数
      plateOut: 0,
      // 批次品种
      seedingVariety: '',
      // 批次种子来源
      seedSource: '',
      // 品种名称
      varietyname: '',
      // 品种详情对象
      count: {}
    };
  },
  methods: {
    // 订单详情页回调函数
    closemask (item) {
      this.flag = item;
      // console.log(item);
    },
    // 品种详情页面
    getvarirty () {
      // if (this.seedingVariety != '') {
      //   this.flag = true;
      //   console.log(this.seedingVariety, '名称');
      //   this.getvarieryname(this.seedingVariety);
      // }
    },
    // 获取时间
    getTime (date) {
      var now = new Date(date);
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, '0');
      date = date.toString().padStart(2, '0');
      var defaultDate = `${ year }年${ month }月${ date }日`;
      this.date = defaultDate;
      // console.log(this.Date, '时间');
    },
    // 获取育秧情况批次
    getselect () {
      getSelect()
        .then(res => {
          console.log(res);
          this.options = res.data;
          this.value1 = res.data[0].patch;
          // console.log(this.value1, 111111);
          this.getseedingyearname();
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 改变批次事件
    getname () {
      this.getseedingyearname();
    },
    // 查询批次信息
    getseedingyearname () {
      let name = this.value1;
      getSeedingYearName(name)
        .then(res => {
          console.log(res)
          ;(this.seedingBeginTime = res.data.seedingBeginTime),
          (this.plateMyself = res.data.plateMyself);
          this.plateOut = res.data.plateOut;
          this.seedingVariety = res.data.seedingVariety;
          this.seedSource = res.data.seedSource;
          this.getTime(this.seedingBeginTime);
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取育秧情况
    getList () {
      getSeedingYear({})
        .then(res => {
          this.countSeeding = res.data.countSeeding;
          this.countBatch = res.data.countBatch;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取品种详情
    getvarieryname (data) {
      console.log(data, 11111);
      getSeedingVarietyName(data)
        .then(res => {
          console.log(res);
          this.count = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  box-sizing: border-box;
}

::v-deep.el-select {
  color: #ffffff;

  .el-input__inner {
    font-size: companyW(16vw);
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: #ffffff;
    text-align: center;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }

  .el-select__caret {
    font-size: companyW(16vw);
    color: #ffffff;
  }

  .el-input__icon {
    height: companyH(40vh);
  }
}

.power {
  position: relative;
  top: companyH(60vh);
  left: companyW(30vw);
  width: companyW(422vw);
  height: companyH(70vh);
  display: flex;
  justify-content: space-around;

  .content-item {
    width: companyW(135vw);
    height: companyH(57vh);

    .item-name {
      margin-left: companyW(5vw);
      display: inline-block;
      margin-bottom: companyH(7vh);
      width: companyW(142vw);
      height: companyH(13vh);
      font-size: companyW(18vw);
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #eff0f1;
      line-height: companyH(17vh);
      text-align: center;
    }

    .item-number {
      text-align: center;
      width: 112%;
      height: companyH(47vh);
      background-image: url(../../../../../assets/digital-page/power.png);
      background-size: cover;
      font-size: companyW(18vw);
      font-family: DIN;
      font-weight: bold;
      color: #ffffff;
      line-height: companyH(47vh);
    }
  }
}

.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(372vh);
  // opacity: 1;
  color: #ffffff;

  .line {
    position: absolute;
    top: companyH(154vh);
    left: companyW(23vw);
    width: companyW(438vw);
    height: companyH(2vh);
    background: url('../../../../../assets/digital-page/xian.png') no-repeat
      center;
  }

  .carous {
    width: 100%;
    height: companyH(302vh);
  }

  .dry-power-content {
    margin: 0 auto;
    margin-top: companyH(120vh);
    width: 100%;
    height: companyH(240vh);

    .power-content-top {
      width: 100%;
      height: companyH(51vh);
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      // background-color: #E69635;
      .content-top-item {
        width: companyW(135vw);
        height: companyH(57vh);

        .top-item-name {
          margin-left: companyW(5vw);
          display: inline-block;
          margin-bottom: companyH(7vh);
          height: companyH(13vh);
          font-size: companyH(18vh);
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #eff0f1;
          line-height: companyH(17vh);
        }

        .top-item-number {
          text-align: center;
          width: 100%;
          height: companyH(47vh);
          background-image: url(../../../../../assets/digital-page/power.png);
          background-size: contain;
          font-size: companyW(16vw);
          font-family: DIN;
          font-weight: bold;
          color: #ffffff;
          line-height: companyH(47vh);
          // white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

        }
      }
    }

    .power-content-bottom {
      margin-top: companyH(39vh);
    }
  }
}
</style>
