<template>
  <div class="middle">
    <div class="middle-nav">
      <!-- <navList :Option="navList"/> -->
      <navList />
    </div>
    <!-- <storageAndDry/> -->
    <div class="middle-tray">
    </div>
  </div>
</template>

<script>
import navList from '../components/navList.vue';
// import storageAndDry from './storageAndDry.vue';
export default {
  components: {
    navList,
    // storageAndDry,
  },
  data() {
    return {
      navList: [
        {
          name: '批次烘干能力',
          number: '1000 吨/批次',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        },
        {
          name: '当年已烘数量',
          number: '80 吨',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        },
        {
          name: '待烘数量',
          number: '43 吨',
          bgcTitle: require('../../../assets/drying-page/nav_bgc.png')
        }
      ]
    };
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.middle {
  position: relative;
  width: companyW(916vw);
  height: companyH(978vh);
  overflow: hidden;
  background-image: url("../../../assets/digital-page/bgc2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  .middle-nav {
    width: companyW(716vw);
    height: companyH(65vh);
    margin: 0 auto;
    margin-top: companyH(44vh);
  }

  .middle-tray {
    position: absolute;
    margin-left: companyW(-70vw);
    width: companyW(1092vw);
    height: companyH(230vh);

    // background-image: url(../../../assets/drying-page/tray.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
