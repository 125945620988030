<template>
  <!-- 头部导航栏组件 -->
  <div class="middle-navList">
    <div class="middle-navList-item">
      <span class="navList-item-title">育秧能力</span>
      <div class="navList-item-desc">
        {{ count.allCoutAlready }}
      </div>
    </div>
    <div class="middle-navList-item">
      <span class="navList-item-title">已育数量</span>
      <div class="navList-item-desc">
        {{ count.allCount }}
      </div>
    </div>
    <div class="middle-navList-item">
      <span class="navList-item-title">未育数量</span>
      <div class="navList-item-desc">
        {{ count.allCountNot }}
      </div>
    </div>
  </div>
</template>

<script>
import { getTop } from '../../../api/seedlingApi/seedling';
export default {
  props: {

  },

  data() {
    return {
      navList: [
        {
          name: '育秧能力',
          number: '5000盘',
          bgcTitle: require('../../../assets/digital-page/waikuang.png')
        },
        {
          name: '已育数量',
          number: '400盘',
          bgcTitle: require('../../../assets/digital-page/waikuang.png')
        },
        {
          name: '未育数量',
          number: '100盘',
          bgcTitle: require('../../../assets/digital-page/waikuang.png')
        }
      ],
      count: {}
    };
  },
  mounted() {
    this.gettop()
  },
  methods: {
    gettop() {
      getTop().then(res => {
        console.log(res);
        this.count = res.data
      }).catch(err => {
        console.log(err);
      })
    }
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.middle-navList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 700px;

  .middle-navList-item {
    width: companyW(208vw);
    height: 100%;
    position: relative;

    .navList-item-title {
      position: absolute;
      top: companyH(-7vh);
      left: 50%;
      transform: translate(-50%);
      height: companyH(18vh);
      font-size: companyH(20vh);
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFE7DF;
      line-height: companyH(18vh);
    }

    .navList-item-desc {
      width: 100%;
      text-align: center;
      height: companyH(65vh);
      background-image: url(../../../assets/digital-page/waikuang.png);
      background-repeat: no-repeat;
      background-size: contain;
      font-size: companyH(30vh);
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: companyH(75vh);
    }
  }
}
</style>
