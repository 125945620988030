<template>
  <!-- 育秧总览累计育秧柱形图 -->
  <div class="dry-service">
    <moduleTitle title="累计育秧服务"></moduleTitle>
    <div class="dry-service-echart">
      <Echart :option="option"></Echart>
    </div>
  </div>
</template>

<script>
import moduleTitle from "./../moduleTitle.vue";
import Echart from "../../../../components/echarts/index.vue";
import option from "../../config/seeding-overview/service.js";
import { getSeedingServer } from "@/api/seedlingApi/seedling.js";;

export default {
  components: {
    moduleTitle,
    Echart,
  },
  data() {
    return {
      option,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getSeedingServer({})
        .then((res) => {
          this.option.series[0].data = res.data.map((x) => {
            return x.seedingCount;
          });
          this.option.series[1].data = res.data.map((x) => {
            return x.plateMyself;
          });
          this.option.series[2].data = res.data.map((x) => {
            return x.plateOut;
          });

          this.option.xAxis.data = [];

          res.data.map((value, index, array) => {
            this.option.xAxis.data.push({
              value: array[index].year,
              textStyle: {
                color: "#ffffff",
                fontSize: 16,
              },
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-service {
  position: relative;

  width: companyW(482vw);
  height: companyH(342vh);
  background: rgba(0, 20, 36, 0.4);
  // background-color: blue;
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
  // opacity: 0.74;

  .dry-service-echart {
    margin: 0 auto;
    margin-top: companyH(40vh);
    width: companyW(400vw);
    height: companyH(260vh);
  }
}
</style>
