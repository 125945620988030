<template>
  <!-- 育秧总览右侧饼图 -->
  <div class="dry-service">
    <div class="dry-service-echart">
      <Echart :option="option"></Echart>
    </div>
  </div>
</template>
    
<script>
import Echart from "@/components/echarts/index.vue";
export default {
  props: {
    propOptions: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  components: {
    Echart,
  },
  data() {
    return {
      option: this.propOptions,
    };
  },
};
</script>
    
<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-service {
  position: relative;
  width: companyW(450vw);
  height: companyH(270vh);
  background: rgba(0, 20, 36, 0.4);
  // opacity: 0.74;
  top: companyH(30vh);

  .dry-service-echart {
    position: absolute;
    margin: 0 auto;
    width: companyW(450vw);
    height: companyH(270vh);
    left: companyW(-104vw);
    top: companyH(-14vh);

  }
}
</style>
    