<template>
  <div class="Right">
    <!-- 育秧总览右侧育秧情况 -->
    <div class="right-nav">
      <ModuleTitle title="当年育秧情况" />
      <Power></Power>
    </div>
    <!-- 育秧总览右侧对比上年情况 -->
    <div class="right-tray">
      <ModuleTitle title="对比上年情况" />
      <!-- 右侧仪表盘 -->
      <rightCont :propOptions="dbsnOption"></rightCont>
      <!--插入的图片和自定义图例  -->
      <div class="Tulie">
        <div class="tulie">
          <div class="title"></div>
          <span>今年育秧盘数</span>
          <p>{{ tabData.thisYear }}盘</p>
        </div>
        <img src="../../../../../assets/digital-page/juxing2420.png" alt="" />
        <div class="tulie2">
          <div class="title2"></div>
          <span>上年育秧盘数</span>
          <p>{{ tabData.lastYear||0 }}盘</p>
        </div>

        <img src="../../../../../assets/digital-page/juxing2420(2).png" alt="" />
      </div>
    </div>
    <div class="right-pan">
      <ModuleTitle title="应急保障订单" />
      <div class="img">
        <p>{{ yjData }}</p>
        <img src="../../../../../assets/digital-page/yuan.png" alt="" />
      </div>
      <p class="text">应急保障(盘)</p>
    </div>
  </div>
</template>

<script>
import ModuleTitle from '../../moduleTitle.vue';
import Power from './rightPower.vue';
import rightCont from '../rightybg/index.vue';
import * as rightybg from '../../../config/seeding-overview/rightybg.js';
import { getInf, getLastYear } from '@/api/seedlingApi/seedling.js';

export default {
  components: {
    ModuleTitle,
    rightCont,
    Power,
  },
  data() {
    return {
      ...rightybg,
      yjData: '',
      tabData: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getInf({})
        .then(res => {
          this.yjData = res.data[0].emergency;
        })
        .catch(err => {
          console.log(err);
        });
      getLastYear({})
        .then(res => {
          console.log(res,'aasdasdasdasd');
          this.tabData = res.data;
          this.dbsnOption.title[0].text = `{a|${ (
            (res.data.thisYear / res.data.lastYear||1) *
            100
          ).toFixed(0) }}{b|%}`;
          let data = ((res.data.thisYear / res.data.lastYear) * 100).toFixed(0);

          if (data >= 100) {
            data = 99.99;

            this.dbsnOption.series[0].axisLine = {
              show: true,
              lineStyle: {
                color: [
                  [ 0, '#ffa235' ],
                  [
                    data /
                    100,
                    'rgba(60,84,14,0)',
                  ],
                  [ 1, 'rgba(195,230,100,1)' ],
                ],
                width: 8,
              },
            };
          } else {

            this.dbsnOption.series[0].axisLine = {
              show: true,
              lineStyle: {
                color: [
                  [ 0, '#ffa235' ],
                  [
                    data /
                    100,
                    'rgba(60,84,14,0)',
                  ],
                  [ 1, 'rgba(195,230,100,1)' ],
                ],
                width: 8,
              },
            };
          }


        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.Right {
  position: relative;
  width: companyW(482vw);
  height: 100%;

  .right-nav {
    width: companyW(482vw);
    height: companyH(372vh);
    margin: 0 auto;
    background: rgba(0, 20, 36, 0.4);
    border: 1px solid;
    border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
    // opacity: 0.74;
  }

  .right-tray {
    position: relative;
    // margin-left: companyW(-70vw);
    width: companyW(482vw);
    height: companyH(302vh);
    margin-top: companyH(24vh);
    // background-color:red;
    background: rgba(0, 20, 36, 0.4);
    border: companyW(1vw) solid;
    border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
    // opacity: 0.74;

    .Tulie {
      position: absolute;
      right: companyW(50vw);
      top: companyH(100vh);
      color: #fff;
      font-size: companyH(12vh);

      img {
        margin-bottom: companyH(20vh);
      }

      .tulie {
        position: relative;
        left: companyW(6vw);

        .title {
          display: inline-block;
          width: companyW(10vw);
          height: companyH(10vh);
          background-color: #99c327;
          margin-right: companyW(5vw);
        }

        span {
          font-size: companyH(16vh);
        }

        p {
          position: absolute;
          color: #99c327;
          font-size: companyH(20vh);
          width: companyW(130vw);
          height: companyH(41vh);
          text-align: center;
          line-height: companyH(41vh);
        }
      }

      .tulie2 {
        position: relative;
        left: companyW(6vw);

        .title2 {
          display: inline-block;
          width: companyW(10vw);
          height: companyH(10vh);
          background-color: #00f5e1;
          margin-right: companyW(5vw);
        }

        span {
          font-size: companyH(16vh);
        }

        p {
          position: absolute;
          color: #00f5e1;
          font-size: companyH(20vh);
          width: companyW(130vw);
          height: companyH(41vh);
          text-align: center;
          line-height: companyH(41vh);
        }
      }
    }
  }

  .right-pan {
    position: absolute;
    width: companyW(482vw);
    height: companyH(267vh);
    margin-top: companyH(24vh);
    background: rgba(0, 20, 36, 0.4);
    border: 1px solid;
    border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
    // opacity: 0.74;

    // background-color:red;
    .img {
      position: relative;
      // width: 50%;
      // left: companyW(15vw);
      top: companyH(30vh);
      color: #1afcff;
      font-size: companyH(35vh);
      text-align: center;

      p {
        position: relative;
        // left: companyW(2vw);
        top: companyH(73vh);
      }
    }

    .text {
      font-size: companyH(20vh);
      color: #fff;
      position: absolute;
      font-family: Source Han Sans CN;
      font-weight: 700;
      left: companyW(195vw);
      top: companyW(400vh);
    }
  }
}
</style>
