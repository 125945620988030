let value = 79;

export const dbsnOption = {
  backgroundColor:'rgba(60,84,14,0)',
  title: [
    {
      text: `{a|${value}}{b|%}`,
      x: "center",
      y: "center",
      textStyle: {
        rich: {
          a: {
            fontSize: 30                      ,
            color: "#fff",
            padding:[0,0,4,0]
          },
          b: {
            fontSize: 16,
            color: "#fff",
            padding: [0, 0, 8, 0],
          },
        },
      },
    },
    
    {
      text: `对比上年完成度`,
      bottom: "0%",
      x: "center",
      textStyle: {
        color: "#fff",
        fontSize:18
      },
    },
  ],      
  series: [
    {
      type: "gauge",
      radius: "45%",
      clockwise: false,
      startAngle: "90",
      endAngle: "-269.9999",
      splitNumber: 25,
      detail: {
        offsetCenter: [0, -20],
        formatter: " ",
      },
      pointer: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: [
            [0, "#ffa235"],
            [78 / 100,"rgba(60,84,14,0)"],
            [1,  "rgba(195,230,100,1)"],
          ],
          width: 10,
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        length: 10,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
          width: 2,
        },
      },
      axisLabel: {
        show: false,
      },
    },
    {
      type: "pie",
      name: "内层细圆环",
      radius: ["52%", "54%"],
      // radius: ["42%", "44%"],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          color: "rgb(195,230,100)",
        },
      },
      label: {
        show: false,
      },
      data: [100],
    },
    {
      name: "大环",
      type: "gauge",
      splitNumber: 70,
      radius: "63%",
      // radius: "54%",
      center: ["50%", "50%"],
      startAngle: 90,
      endAngle: -269.9999,
      axisLine: {
        show: false,
        lineStyle: {
          color: [[1, "rgb(121,165,1)"]],
        },
      },
            
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        length: 2,
        lineStyle: {
          color: "auto",
          width: 5.5,
        },
      },
      axisLabel: {
        show: false,
      },
      detail: {
        show: false,
      },
    },
    {
      type: "pie",
      name: "内层环",
      // radius: [0, "30%"],
      radius: [0, "30%"],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          // color: "red",
          color: "rgba(195,230,100,0)",
        },
      },
      label: {
        show: false,
      },
      data: [100],
    },
  ],
};
