<template>
  <!-- 左侧育秧情况圆环ercharts -->
  <div class="dry-lastYear">
    <moduleTitle title="育秧情况"></moduleTitle>
    <div class="dry-lastYear-content">
      <div class="lastYear-content-echart">
        <Echart :option="option"></Echart>

        <!-- <el-date-picker v-model="currentTime" value-format="yyyy" format="yyyy" type="year" placeholder="选择年"
            >
          </el-date-picker> -->

        <el-select v-model="value1" placeholder="请选择" @change="timeChange">
          <el-option v-for="item, index in options" :key="index" :label="item.year" :value="item.year">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from "./../moduleTitle.vue";
import Echart from "../../../../components/echarts/index.vue";
import option from "../../config/seeding-overview/lastyear.js";
import { getSeedingCondition, getYear } from "@/api/seedlingApi/seedling.js";;

export default {
  components: {
    moduleTitle,

    Echart,
  },
  data() {
    return {
      option,
      // currentTime: new Date().getFullYear().toString(),
      options: [],
      value1: 0,
    };
  },
  created() {

    this.getyear()
  },
  methods: {
    // 获取育秧情况年份年份
    getyear() {
      getYear().then(res => {
        console.log(res);
        this.options = res.data
        this.value1 = res.data[0].year
        this.getList();
      }).catch(err => {
        console.log(err);
      })
    },

    getList() {
      getSeedingCondition({
        time: this.value1,
      })
        .then((res) => {
          this.option.title.text = `${res.data.seedingCount}盘\n\n总育秧量`,
            this.option.series[0].data[0] = { value: res.data.plateOut, name: "对外育苗" }
          this.option.series[0].data[1] = { value: res.data.plateMyself, name: "自我育苗" }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    timeChange(val) {
      console.log(val);
      this.value1 = val
      this.getList()
    }
  },
};
</script>

<style  lang="scss" >
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}


.dry-lastYear {
  position: relative;
  width: companyW(482vw);
  height: companyH(312vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
  // opacity: 0.74;

  .dry-lastYear-content {
    margin: 0 auto;
    margin-top: companyH(40vh);
    width: companyW(400vw);
    height: companyH(260vh);

    // background-color: #E69635;
    .lastYear-content-echart {
      width: companyH(240vw);
      height: companyH(260vh);
      position: absolute;
      left: companyW(-20vw);
      top: companyH(45vh);

      // background-color: red;

      .el-select {
        position: absolute;
        right: companyW(-30vw);
        top: companyH(20vh);
        background: #1e2801;
        z-index: 3;

        .el-input__inner {
          width: companyW(110vw);
          height: companyH(30vh);
          background: #1e2801;
          border-radius: 0;
          border: #8fc201 companyW(1vw) solid;
          font-size: companyH(18vh);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #e1eaea;
        }

        .el-input__icon {
          line-height: companyH(30vh);
        }
      }


    }
  }
}
</style>
