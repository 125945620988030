<template>
    <div>
        <!-- 新增委托订单 -->
        <el-dialog :visible.sync="flag" :show-close="false" @close="closemask" :modal="false">
            <dv-border-box-1 :color="['#99C327', '#D6E2B5']">
                <div class="toolBox">
                    <div class="del" @click="clickdel">X</div>
                    <div class="box">
                        <div class="img">
                            <img :src="count.img" alt="">
                        </div>
                        <div class="text">
                            <!-- <p>籼型三系杂交水稻品种。长江上游作中稻种植，全生育期156.3天，比对照Ⅱ优838短0.5天。株高111.6厘米，穗长25.6厘米，亩有效穗数15.2万穗，穗粒数169.0粒，结实率80.8%，千粒重29.0克，易倒伏。抗性：稻瘟病综合指数3.6，穗瘟损失率最高级5级；褐飞虱9级；中感稻瘟病，高感褐飞虱。米质主要指标：整精米率58.7%，长宽比3.5，垩白粒率28%，垩白度2.3%，胶稠度75毫米，直链淀粉含量17.5%，达到国家《优质稻谷》标准2级籼型三系杂交水稻品种。长江上游作中稻种植，全生育期156.3天，比对照Ⅱ优838短0.5天。株高111.6厘米，穗长25.6厘米，亩有效穗数15.2万穗，穗粒数169.0粒，结实率80.8%，千粒重29.0克，易倒伏。抗性：稻瘟病综合指数3.6，穗瘟损失率最高级5级；褐飞虱9级；中感稻瘟病，高感褐飞虱。米质主要指标：整精米率58.7%，长宽比3.5，垩白粒率28%，垩白度2.3%，胶稠度75毫米，直链淀粉含量17.5%，达到国家《优质稻谷》标准2级籼型三系杂交水稻品种。长江上游作中稻种植，全生育期156.3天，比对照Ⅱ优838短0.5天。株高111.6厘米，穗长25.6厘米，亩有效穗数15.2万穗，穗粒数169.0粒，结实率80.8%，千粒重29.0克，易倒伏。抗性：稻瘟病综合指数3.6，穗瘟损失率最高级5级；褐飞虱9级；中感稻瘟病，高感褐飞虱。米质主要指标：整精米率58.7%，长宽比3.5，垩白粒率28%，垩白度2.3%，胶稠度75毫米，直链淀粉含量17.5%，达到国家《优质稻谷》标准2级
                            </p> -->
                            <!-- <p>{{ count.content }}</p> -->
                            <div class="box1">
                                <div class="box2">
                                    <p>生育期:</p>
                                    <p>{{ count.shengyuqi }} 天</p>
                                </div>
                                <div class="box2">
                                    <p>株高:</p>
                                    <p>{{ count.zhugao }} 厘米</p>
                                </div>
                                <div class="box2">
                                    <p>单产:</p>
                                    <p>{{ count.danchan }} 粒</p>
                                </div>
                                <div class="box2">
                                    <p>千粒重:</p>
                                    <p>{{ count.qianlizhong }} 克</p>
                                </div>
                                <div class="box2">
                                    <p>整精米率:</p>
                                    <p>{{ count.zhengjingmilv }} %</p>
                                </div>
                                <div class="box2">
                                    <p>垩白度:</p>
                                    <p>{{ count.ebaidu }} %</p>
                                </div>
                                <div class="box2">
                                    <p class="p1">稻瘟病抗性:</p>
                                    <p class="p1">{{ count.kangxing }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </dv-border-box-1>
        </el-dialog>
    </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Boolean,
      default: false
    },
    count: {
      type: Object
    }
  },
  components: {},
  data() {
    return {
      // count: {},
    };
  },
  watch: {
    count: {
      handler(newValue) {
        this.count = newValue;
      },
      deep: true
    }

  },
  created() {
  },
  methods: {
    // 弹框回调函数
    closemask() {
      this.$emit('closemask', false);
    },
    // 点击关闭按钮
    clickdel() {
      this.flag = false;
      // console.log(1111);
    },
  }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

* {
    font-family: Source Han Sans CN;
    font-size: companyW(18vw);
    box-sizing: border-box;
    color: #fff;
}

::v-deep .el-dialog {
    width: companyW(1430vw);
    height: companyH(620vh);
    background-color: #000;
    border-radius: companyH(40vh);
    // opacity: 0.8;
    margin: 0 auto;

    .el-input__icon {
        line-height: companyH(30vh);
    }
}

.dv-border-box-1 {
    position: relative;
    width: companyW(1430vw);
    height: companyH(620vh);
}

::v-deep .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
}

::v-deep .el-dialog__body {
    padding: 0;
}

::v-deep .el-dialog__header {
    padding: 0;
}



.toolBox {
    height: companyH(580vh);
    padding-left: companyW(80vw);
    padding-right: companyW(80vw);
    position: relative;

    .del {
        position: absolute;
        top: companyH(30vh);
        right: companyH(30vh);
        width: companyW(40vw);
        height: companyH(40vh);
        text-align: center;
        line-height: companyH(35vh);
        border-radius: 50%;
        font-size: companyH(30vh);
        color: #000;
        font-weight: 500;
        background-color: #fff;

    }

    .box {
        position: relative;
        top: companyH(50vh);
        left: companyW(30vw);
        width: companyW(1200vw);
        height: companyH(482vh);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .img {
            width: companyW(340vw);
            height: companyH(285vh);
            box-sizing: border-box;
            border: companyH(2vh) dashed rgba(255, 255, 255, 0.5);
            background: url('../../../../../assets/digital-page/tupianbgc.png') no-repeat center;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: companyW(50vw);

            img {
                width: companyW(245vw);
                height: companyH(241.4vh);
            }
        }

        .text {
            width: companyW(980vw);
            height: companyH(400vh);
            color: #fff;
            font-size: companyH(16vh);
            font-family: Source Han Sans CN;
            font-weight: 400;
            background: rgba(153, 195, 39, 0);
            border: companyH(2vh) solid rgba(153, 195, 39, 0.4);
            border-radius: companyH(10vh);
            padding: companyW(10vw);
            overflow-y: auto;
            overflow-x: hidden;
            line-height: 1.5;
            position: relative;

            .box1 {
                position: absolute;
                left: companyW(100vw);
                top: companyH(20vh);
                width: companyW(750vw);
                height: companyH(297vh);
                display: flex;
                flex-wrap: wrap;

                .box2 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: companyW(20vw);


                    p {
                        width: companyW(350vw);
                        font-size: companyW(22vw);

                    }

                    .p1 {
                        width: companyW(700vw);

                    }
                }
            }
        }

        .text::-webkit-scrollbar {
            width: companyW(5vw);
        }

        .text::-webkit-scrollbar-track {
            box-shadow: inset 0 0 companyW(5vw) rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background-color: rgba(0, 0, 0, 0.1);
        }

        .text::-webkit-scrollbar-thumb {
            background-color: #99c327;
            box-shadow: inset 0 0 companyW(5vw) rgba(0, 0, 0, 0.2);
            border-radius: companyW(5vw);
        }
    }


}
</style>
