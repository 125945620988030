<template>
  <!-- 育秧表格样式 -->
  <div class="dry-power">
    <moduleTitle title="育秧能力"></moduleTitle>
    <div class="dry-power-content">
      <div class="power-content-top">
        <div class="content-top-item">
          <span class="top-item-name">育秧中心面积(㎡)</span>
          <div class="top-item-number">
            {{ listData.seedingArea }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">播种流水线（条）</span>
          <div class="top-item-number">
            {{ listData.seedLine }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">育秧盘数量（个）</span>
          <div class="top-item-number">
            {{ listData.seedingPlateCount }}
          </div>
        </div>
      </div>
      <div class="power-content-bottom power-content-top">
        <div class="content-top-item">
          <span class="top-item-name">暗室面积（㎡）</span>
          <div class="top-item-number">
            {{ listData.darkroomArea }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">大棚面积（㎡）</span>
          <div class="top-item-number">
            {{ listData.greenhouseArea }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">露天秧田面积（亩）</span>
          <div class="top-item-number">
            {{ listData.outdoorSeedingArea }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from './../moduleTitle.vue';
import { getInf } from '@/api/seedlingApi/seedling.js';

export default {
  components: {
    moduleTitle,
  },
  data() {
    return {
      listData: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getInf({})
        .then(res => {
          this.listData = res.data[0];
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(292vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
  // opacity: 0.74;

  .dry-power-content {
    margin: 0 auto;
    margin-top: companyH(90vh);
    width: companyW(442vw);
    height: companyH(130vh);

    // background-color: red;
    .power-content-top {
      width: 100%;
      height: companyH(51vh);
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      // background-color: #E69635;
      .content-top-item {
        width: companyW(135vw);
        height: companyH(57vh);

        .top-item-name {
          margin-left: companyW(5vw);
          display: inline-block;
          margin-bottom: companyH(7vh);
          height: companyH(13vh);
          font-size: companyH(18vh);
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #eff0f1;
          line-height: companyH(17vh);
          width: companyW(170vw);
        }

        .top-item-number {
          text-align: center;
          width: 100%;
          height: companyH(47vh);
          background-image: url(../../../../assets/digital-page/power.png);
          background-size: contain;
          font-size: companyW(18vw);
          font-weight: bold;
          font-family: DIN;
          color: #ffffff;
          line-height: companyH(47vh);
        }
      }
    }

    .power-content-bottom {
      margin-top: companyH(39vh);
    }
  }
}
</style>
